import { apiInstance } from "_utils/axiosInstance";

export async function createS3Connection(requestData: any) {
  try {
    const { data } = await apiInstance.post(
      "/external-storage/s3-connection",
      requestData,
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createGCSConnection(requestData: any) {
  try {
    const { data } = await apiInstance.post(
      "/external-storage/gcs-connection",
      requestData,
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
}

export const removeS3Connection = async (s3Id: any) => {
  try {
    const { data } = await apiInstance.delete(
      `/external-storage/s3-connection/${s3Id}`,
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateS3BucketInfo = async (s3Id: any, updatedData: any) => {
  try {
    const { data } = await apiInstance.patch(
      `/external-storage/s3-connection/${s3Id}/update-info`,
      updatedData,
    );
    return data;
  } catch (error) {
    console.log(error, "error");
  }
};

// s3
export const getS3Connection = async () => {
  try {
    const { data } = await apiInstance.get(`/external-storage/s3-connection`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getS3ConnectionFiles = async (s3Id: any, prefix?: string) => {
  try {
    const { data } = await apiInstance.get(
      `/external-storage/s3-connection/${s3Id}/files${prefix ? `?prefix=${prefix}` : ""}`,
    );

    return data.data.files;
  } catch (error) {
    console.log(error);
  }
};

export const createS3Folder = async (requestData: any) => {
  try {
    const { data } = await apiInstance.post(
      "/external-storage/s3-connection/create-folder",
      requestData,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

// oci
export const getOCIConnection = async () => {
  try {
    const { data } = await apiInstance.get(`/external-storage/oci-connection`);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOCIConnectionFiles = async (ociId: any, prefix?: string) => {
  try {
    const { data } = await apiInstance.get(
      `/external-storage/oci-connection/${ociId}/files${prefix ? `?prefix=${prefix}` : ""}`,
    );

    return data.result;
  } catch (error) {
    console.log(error);
  }
};

// google
export const getGCSConnetion = async () => {
  try {
    const { data } = await apiInstance.get(`/external-storage/gcs-connection`);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getGCSConnectionFiles = async (gcsId: any, prefix?: string) => {
  try {
    const { data } = await apiInstance.get(
      `/external-storage/gcs-connection/${gcsId}/files${prefix ? `?prefix=${prefix}` : ""}`,
    );

    return data.result;
  } catch (error) {
    console.log(error);
  }
};
